/* eslint-disable no-secrets/no-secrets */
/* eslint-disable unicorn/template-indent */
import gql from 'graphql-tag';

export const EVENT_VARIANT_FRAGMENT = gql`
  fragment EventVariantFields on EventVariant {
    title
    shopifyProductVariantId
    sku
    price {
      EUR: eur
      USD: usd
      AUD: aud
      GBP: gbp
    }
    compareAtPrice {
      EUR: eur
      USD: usd
      AUD: aud
      GBP: gbp
    }
    eventStart
    eventEnd
    saleEnd
    doorsOpen
    stream {
      title
      dashUrl
      dashCastUrl
      hlsUrl
      duration
    }
    optionsCollection {
      items {
        sys {
          id
        }
        position
        value
      }
    }
    iconsCollection {
      items {
        type
      }
    }
    verticalIconsCollection {
      items {
        type
      }
    }
  }
`;
