'use client';

import { useEffect, useState } from 'react';
import type { FC, ReactNode } from 'react';
import { usePathname } from 'next/navigation';
import Script from 'next/script';
import { AnalyticsBrowser } from '@segment/analytics-next';
import classNames from 'classnames';
import { getCookie } from 'cookies-next';
import { useGetEnvironmentVariable } from '@/hooks/useGetEnvironmentVariable';
import { ContentfulEventCarousel } from '@/types/contentful';
import { OGP } from '@/types/page-context';
import { useIsPreviewMode } from '@/utils/contentful/useIsPreviewMode';
import { ButtonType } from '@/utils/enum';
import { commonTexts } from '@/utils/messages/common';
import { pushToElevarDataLayer } from '@/utils/pushToElevarDataLayer';
import { getCanonicalLink, renderSchemaCarousel, renderSchemaOrganization } from '@/utils/seo';
import { vwoSmartCodeScript } from '@/utils/vwo-script';
import { useAppSelector } from '../state/hooks';
import { getItemFromLocalStorage } from '../state/localStorage';
import { MessageBanner } from './banner';
import Button from './Button';
import Footer from './footer';
import type { FooterProps } from './footer';
import Navbar from './navbar/Navbar';
import NewsletterSignup from './newsletter-signup/NewsletterSignup';
import { SegmentScript } from './SegmentScript';
interface ShellProps {
  readonly children: ReactNode;
  readonly isFooterTopLineDisabled?: FooterProps['disableTopLine'];
  readonly isNewsletterSignupVisible?: boolean;
  readonly banner?: ReactNode;
  readonly pathname?: string;
  readonly slug?: string;
  readonly ogp?: OGP;
  readonly schemaData?: {
    carousel?: ContentfulEventCarousel;
    prerendered?: string;
  };
}
const LATEST_PLATFORM_MESSAGE_DATE = 'latestPlatformMessageDate';

// Translate site on static build
const handlePreviewModeExit = () => {
  if (typeof window === 'undefined') return;
  window.location = '/api/exit-draft' as any;
};
const ShellClientSide: FC<ShellProps> = ({
  children,
  slug,
  isFooterTopLineDisabled,
  banner,
  isNewsletterSignupVisible,
  schemaData,
  ogp
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // pathname,
}) => {
  const {
    title,
    description,
    imageUrl
  } = ogp ?? {};
  const onAirUrl = 'onair.events';
  const canonicalLink = getCanonicalLink(onAirUrl, slug ?? '');
  const {
    SEGMENT_ANALYTICS_TRACK_WRITE_KEY,
    SEGMENT_CDN_PROXY_HOST,
    SEGMENT_API_PROXY_HOST,
    ENABLE_SEGMENT,
    DATA_DOMAIN_SCRIPT
  } = useGetEnvironmentVariable(['SEGMENT_ANALYTICS_TRACK_WRITE_KEY', 'SEGMENT_CDN_PROXY_HOST', 'SEGMENT_API_PROXY_HOST', 'ENABLE_SEGMENT', 'DATA_DOMAIN_SCRIPT']);
  useEffect(() => {
    window.ElevarInvalidateContext?.();
    window.ElevarDataLayer = window.ElevarDataLayer ?? [];
  }, []);
  const trackingPreferencesCookie = getCookie('tracking-preferences');
  const marketingAndAnalytics = trackingPreferencesCookie ? JSON.parse(trackingPreferencesCookie).custom?.marketingAndAnalytics : undefined;
  const pathname = usePathname();
  useEffect(() => {
    if (!window.location.search.includes('watch-trailer')) {
      window.scroll(0, 0);
    }
  }, [pathname]);
  useEffect(() => {
    if (!ENABLE_SEGMENT || !SEGMENT_ANALYTICS_TRACK_WRITE_KEY || !SEGMENT_CDN_PROXY_HOST || !SEGMENT_API_PROXY_HOST) return;
    const analytics = AnalyticsBrowser.load({
      writeKey: !marketingAndAnalytics ? '' : SEGMENT_ANALYTICS_TRACK_WRITE_KEY,
      cdnURL: `https://${SEGMENT_CDN_PROXY_HOST}`
    }, {
      integrations: {
        'Segment.io': {
          apiHost: SEGMENT_API_PROXY_HOST
        }
      }
    });
    if (typeof window !== 'undefined') {
      window.analytics = analytics;
      // if (!marketingAndAnalytics) {
      //     //@ts-ignore
      //     window.analytics.identify = () => null;
      //     //@ts-ignore
      //     window.analytics.page = () => null;
      //     //@ts-ignore
      //     window.analytics.track = () => null;
      // }
    }
  }, [SEGMENT_ANALYTICS_TRACK_WRITE_KEY, SEGMENT_CDN_PROXY_HOST, SEGMENT_API_PROXY_HOST]);
  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [platformPreviousUpdatedAt] = useState(getItemFromLocalStorage(LATEST_PLATFORM_MESSAGE_DATE));
  const {
    description: platformDescription,
    updatedAt: platformUpdatedAt
  } = useAppSelector(state => state.platformMessages);
  useEffect(() => {
    const hideErrorBanner = !platformUpdatedAt || !platformDescription || platformPreviousUpdatedAt === platformUpdatedAt;
    setShowErrorBanner(!hideErrorBanner);
  }, [platformDescription, platformPreviousUpdatedAt, platformUpdatedAt]);
  const isPreviewMode = useIsPreviewMode();
  const loggedIn = useAppSelector(state => state.auth.loginStatus === 'LOGGED_IN');
  const auth = useAppSelector(state => state.auth); // TODO: review ->
  const customerInfo = useAppSelector(state => state.auth.token?.customerInfo);
  const itemsInCart = useAppSelector(state => state.cartSlice.numberOfItemsInCart);
  const cartTotal = useAppSelector(state => state.cartSlice.cartTotalAmmount);
  const normalizedCartList = useAppSelector(state => state.cartSlice.normalizedCartList);
  const isCartInitialized = useAppSelector(state => state.cartSlice.isCartInitialized);
  const showNewsletterSignup = isNewsletterSignupVisible && !loggedIn;
  useEffect(() => {
    window.ElevarInvalidateContext?.();
    window.ElevarDataLayer = window.ElevarDataLayer ?? [];
  }, []);
  useEffect(() => {
    if (DATA_DOMAIN_SCRIPT) {
      const script = document.createElement('script');
      script.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
      script.type = 'text/javascript';
      script.async = true;
      script.setAttribute('data-domain-script', DATA_DOMAIN_SCRIPT);
      document.head.appendChild(script);
    }
  }, [DATA_DOMAIN_SCRIPT]);
  useEffect(() => {
    if (isCartInitialized) {
      const products = normalizedCartList.map(({
        variantTitle,
        productTitle,
        shopifyVariantId,
        shopifyProductId,
        poster,
        price,
        compareAtPrice,
        sku
      }, index) => {
        return {
          id: sku,
          name: productTitle,
          brand: 'OnAir',
          category: 'Dummy category',
          variant: variantTitle || productTitle,
          price: price,
          compare_at_price: compareAtPrice || '0.0',
          quantity: '1',
          list: window.location.pathname,
          product_id: shopifyProductId,
          variant_id: shopifyVariantId,
          image: poster.asset.url,
          url: window.location.href,
          position: index + 1 //position in cart indexed starting at 1
        };
      });
      pushToElevarDataLayer({
        eventName: 'dl_user_data',
        auth: auth.token,
        cartTotal: cartTotal || '0',
        ecommerce: {
          currencyCode: 'EUR',
          cart_contents: {
            products
          }
        }
      });
    }
  }, [isCartInitialized]);
  return <div data-sentry-component="ShellClientSide" data-sentry-source-file="ShellClientSide.tsx">
      {renderSchemaOrganization(slug ?? '')}
      {schemaData?.carousel && renderSchemaCarousel(slug ?? '', schemaData.carousel)}
      {schemaData && <Script type="application/ld+json" id="prerendered">
          {schemaData.prerendered}
        </Script>}
      <Script type="text/javascript" id="vwoCode" dangerouslySetInnerHTML={{
      __html: vwoSmartCodeScript
    }} data-sentry-element="Script" data-sentry-source-file="ShellClientSide.tsx" />
      <head>
        <link rel="shortcut icon" type="image/png" href="/assets/images/favicons/apple-touch-icon.png" />
        <link rel="apple-touch-icon" sizes="180x180" href="/assets/images/favicons/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/assets/images/favicons/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/assets/images/favicons/favicon-16x16.png" />
        <link rel="manifest" href="/assets/images/favicons/site.webmanifest" />
        <link rel="mask-icon" href="/assets/images/favicons/safari-pinned-tab.svg" color="#fc4d3c" />
        {/* vwo smart code */}
        <link rel="preconnect" href="https://dev.visualwebsiteoptimizer.com" />
        <script data-cfasync="false" type="text/javascript" id="vwoCode">
          {vwoSmartCodeScript}
        </script>
        <link rel="canonical" href={canonicalLink} />
        <meta name="msapplication-TileColor" content="#121112" data-sentry-element="meta" data-sentry-source-file="ShellClientSide.tsx" />
        <meta name="theme-color" content="#121112" data-sentry-element="meta" data-sentry-source-file="ShellClientSide.tsx" />
        <title>{title}</title>
        <meta property="og:title" content={title} data-sentry-element="meta" data-sentry-source-file="ShellClientSide.tsx" />
        <meta name="description" property="og:description" content={description} data-sentry-element="meta" data-sentry-source-file="ShellClientSide.tsx" />

        {slug && <meta property="og:url" content={`${onAirUrl}/${slug}`} />}
        <meta property="og:site_name" content={commonTexts.company_name} data-sentry-element="meta" data-sentry-source-file="ShellClientSide.tsx" />
        <meta property="og:image" content={imageUrl ? imageUrl : '/assets/images/favicons/favicon.ico'} data-sentry-element="meta" data-sentry-source-file="ShellClientSide.tsx" />

        {renderSchemaOrganization(slug ?? '')}
        {schemaData?.carousel && renderSchemaCarousel(slug ?? '', schemaData.carousel)}
        {schemaData && <script type="application/ld+json">{schemaData.prerendered}</script>}
        {ENABLE_SEGMENT === 'true' && <SegmentScript />}
      </head>
      <div className="shell-wrapper">
        <MessageBanner type="ErrorMessage" hideBanner={() => setShowErrorBanner(false)} data-sentry-element="MessageBanner" data-sentry-source-file="ShellClientSide.tsx" />

        <Button className="skip-main" type={ButtonType.SOLID_PRIMARY} href="#main" data-sentry-element="Button" data-sentry-source-file="ShellClientSide.tsx">
          Skip to Content
        </Button>

        {banner}

        <Navbar loggedIn={loggedIn} firstName={customerInfo?.firstName} lastName={customerInfo?.lastName} transparentBackground itemsInCart={itemsInCart} isBannerDisplayed={!!banner || showErrorBanner} data-sentry-element="Navbar" data-sentry-source-file="ShellClientSide.tsx" />
        <main id="main" className={classNames('main', {
        'main--with-banner': !!banner || showErrorBanner
      })}>
          {children}
          {showNewsletterSignup && <NewsletterSignup />}
          {isPreviewMode && <div onClick={handlePreviewModeExit} className="exit-preview">
              Exit preview mode
            </div>}
          <Footer disableTopLine={isFooterTopLineDisabled && !showNewsletterSignup} data-sentry-element="Footer" data-sentry-source-file="ShellClientSide.tsx" />
        </main>
      </div>
    </div>;
};
export default ShellClientSide;