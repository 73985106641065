import React from 'react';
import type { FC } from 'react';
import { LinkType } from '@/types/Notification';
import ROUTES from '../../constants/routes';
import { useAppDispatch } from '../../state/hooks';
import { handleLogout } from '../../state/tokenHelper';
import Icon from '../Icon';
import LinkInternal from '../LinkInternal';
import messages from './messages';

// Keep in mind, this is used in multiple places
const SideNavigation: FC = () => {
  const dispatch = useAppDispatch();
  return <div className="side-navigation d-flex flex-column" data-sentry-component="SideNavigation" data-sentry-source-file="SideNavigation.tsx">
      <LinkInternal type={LinkType.LINK_STYLED_IN_COMPONENT} to={ROUTES.myPlace} className="d-flex" data-sentry-element="LinkInternal" data-sentry-source-file="SideNavigation.tsx">
        <Icon icon="oa-home" data-sentry-element="Icon" data-sentry-source-file="SideNavigation.tsx" /> {messages.side_navigation.my_place}
      </LinkInternal>
      <LinkInternal type={LinkType.LINK_STYLED_IN_COMPONENT} to={`${ROUTES.myPlace}${ROUTES.shows}`} className="d-flex" data-sentry-element="LinkInternal" data-sentry-source-file="SideNavigation.tsx">
        <Icon icon="oa-ticket" data-sentry-element="Icon" data-sentry-source-file="SideNavigation.tsx" /> {messages.side_navigation.shows}
      </LinkInternal>
      <LinkInternal type={LinkType.LINK_STYLED_IN_COMPONENT} to={`${ROUTES.myPlace}${ROUTES.profileSettings}`} className="d-flex" data-sentry-element="LinkInternal" data-sentry-source-file="SideNavigation.tsx">
        <Icon icon="oa-user" data-sentry-element="Icon" data-sentry-source-file="SideNavigation.tsx" /> {messages.side_navigation.profile_settings}
      </LinkInternal>

      <button type="button" onClick={() => handleLogout(dispatch)} className="d-flex">
        <Icon icon="oa-logout" data-sentry-element="Icon" data-sentry-source-file="SideNavigation.tsx" /> {messages.side_navigation.log_out}
      </button>
    </div>;
};
export default SideNavigation;