'use client';

import React, { useEffect, useState } from 'react';
import { fetchPlatformMessage } from '@/reducers/platformMessageSlice';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { getItemFromLocalStorage, setItemInLocalStorage } from '../../state/localStorage';
// import { localizedDateFormat } from '../../utils/date';
import Icon from '../Icon';

// import messages from './messages';

// Local storage's value name
const LATEST_PLATFORM_MESSAGE_DATE = 'latestPlatformMessageDate';
export const ErrorMessageBanner = ({
  hideBanner
}: {
  hideBanner?: () => void;
}) => {
  const dispatch = useAppDispatch();
  const [previousUpdatedAt, setPreviousUpdatedAt] = useState(getItemFromLocalStorage(LATEST_PLATFORM_MESSAGE_DATE));
  const {
    description,
    updatedAt
  } = useAppSelector(state => state.platformMessages);
  const handleHideMessage = () => {
    setItemInLocalStorage(LATEST_PLATFORM_MESSAGE_DATE, updatedAt);
    hideBanner?.();
    setPreviousUpdatedAt(updatedAt);
  };

  // get any web platform message
  useEffect(() => {
    dispatch(fetchPlatformMessage({
      platform: 'web'
    }));
  }, [dispatch]);

  // if there is no updatedAt or description or the user has already seen the message don't show it again
  if (!updatedAt || !description || previousUpdatedAt === updatedAt) return null;

  // The commented out parts might be used again when we refactor properly this issue
  // that's why they are not removed

  // updatedAt date in a human readable form
  // const localizedUpdatedAt = localizedDateFormat(
  //     new Date(updatedAt),
  //     'PPP, HH:mm',
  //     'NL',
  //     false,
  // );

  return <span className="platform-message-banner py-1" data-sentry-component="ErrorMessageBanner" data-sentry-source-file="ErrorMessageBanner.tsx">
      <p className="body-text flex-1">{description}</p>
      <Icon icon="oa-close" className="close-icon" onClick={handleHideMessage} data-sentry-element="Icon" data-sentry-source-file="ErrorMessageBanner.tsx" />
    </span>;
};