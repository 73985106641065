/* eslint-disable no-secrets/no-secrets */
import gql from 'graphql-tag';

export const TESTIMONIAL_BLOCK_FRAGMENT = gql`
  fragment TestimonialBlockFields on TestimonialBlock {
    sys {
      id
    }
    title
    testimonialsCollection(limit: 20) {
      items {
        sys {
          id
        }
        authorName
        rating
        date
        content
        authorPicture {
          alt
          asset {
            url
            title
          }
        }
        source {
          ... on Icon {
            type
          }
        }
        jobTitle
      }
    }
  }
`;
