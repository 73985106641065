import gql from 'graphql-tag';

export const CAROUSEL_CONTENT_FRAGMENT = gql`
  fragment CarouselContentTileFields on Tile {
    sys {
      id
    }
    __typename
  }
  fragment CarouselContentEventFields on Event {
    sys {
      id
    }
    __typename
  }
`;

export const CAROUSEL_EVENT_FRAGMENT = gql`
  fragment CarouselEventFields on Event {
    sys {
      id
    }
    __typename
    title
    shortTitle
    slug
    shortDescription
    type
    tags
    shortTitle
    variantsCollection {
      items {
        doorsOpen
        eventStart
      }
    }
    portraitPoster {
      alt
      asset {
        url(transform: { height: 584, format: JPG, quality: 90 })
        width
        height
        title
      }
    }
    poster {
      alt
      asset {
        url(transform: { height: 584, format: JPG, quality: 90 })
        width
        height
        title
      }
    }
    carouselLogo {
      alt
      asset {
        url(transform: { height: 584, format: PNG })
        width
        height
        title
      }
    }
    eventInfoLogo {
      alt
      asset {
        url(transform: { height: 584, format: PNG })
        width
        height
        title
      }
    }
  }
`;

export const CAROUSEL_TILE_FRAGMENT = gql`
  fragment CarouselTileFields on Tile {
    sys {
      id
    }
    __typename
    title
    shortTitle
    sublabel
    shortTitle
    url
    backgroundImage {
      alt
      asset {
        url(transform: { width: 696, format: JPG, quality: 90 })
        width
        height
        title
      }
    }
    logo {
      alt
      asset {
        url(transform: { width: 256, format: PNG })
        width
        height
        title
      }
    }
  }
`;
