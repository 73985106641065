import gql from 'graphql-tag';

export const ACCORDION_BLOCK_FRAGMENT = gql`
  fragment AccordionBlockFields on AccordionBlock {
    sys {
      id
    }
    title
    type
    accordionItemsCollection {
      items {
        sys {
          id
        }
        title
        content {
          json
        }
        icon {
          type
        }
      }
    }
  }
`;
