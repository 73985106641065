import React from 'react';
import classNames from 'classnames';
import { LinkType } from '@/types/Notification';
import { AppStoreBannerSources, AvailableAppStores } from '@/types/Segment';
import { sharedAnalyticsProperties } from '@/utils/analytics';
import { trackAppStoreBanners } from '@/utils/segment-analytics';
import ROUTES from '../../constants/routes';
import { usePlatform } from '../../hooks/usePlatform';
import LinkExternal from '../LinkExternal';
import AppStoreLogo from '../svgs/AppStoreLogo';
import PlayStoreLogo from '../svgs/PlayStoreLogo';
interface AppStoreBannerProps {
  centered?: boolean;
  component: AppStoreBannerSources;
}
export const AppStoreBanner = ({
  centered,
  component
}: AppStoreBannerProps) => {
  const {
    platform
  } = usePlatform();
  const handleAppStoreBannerClick = (store: AvailableAppStores) => {
    trackAppStoreBanners({
      ...sharedAnalyticsProperties,
      trackCallback: window?.analytics?.track,
      store,
      device: 'Mobile App',
      platform: 'Web',
      source: component,
      url: window.location.href
    });
  };
  return <div className={classNames('app-store-banner d-flex flex-row align-items-start w-100', {
    'justify-content-center': centered,
    'align-items-center': centered,
    'text-center': centered
  })} data-sentry-component="AppStoreBanner" data-sentry-source-file="AppStoreBanner.tsx">
      {(platform === 'ios' || platform === 'web') && <div className="item d-flex flex-row">
          <LinkExternal to={ROUTES.appleStore} className="app-store-banner__icon d-flex align-items-center p-0" type={LinkType.LINK_STYLED_IN_COMPONENT} onClick={() => handleAppStoreBannerClick('Apple')}>
            <AppStoreLogo />
          </LinkExternal>
        </div>}
      {(platform === 'android' || platform === 'web') && <div className="item d-flex flex-row">
          <LinkExternal to={ROUTES.playStore} className="app-store-banner__icon d-flex align-items-center p-0" type={LinkType.LINK_STYLED_IN_COMPONENT} onClick={() => handleAppStoreBannerClick('Google')}>
            <PlayStoreLogo />
          </LinkExternal>
        </div>}
    </div>;
};