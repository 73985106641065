/* eslint-disable no-secrets/no-secrets */
import gql from 'graphql-tag';

export const GALLERY_BLOCK_FRAGMENT = gql`
  fragment GalleryBlockFields on GalleryBlock {
    sys {
      id
    }
    title
    preserveAspectRatio
    imagesCollection {
      items {
        alt
        asset {
          url
          height
          width
        }
      }
    }
  }
`;
