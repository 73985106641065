/* eslint-disable no-secrets/no-secrets */
import gql from 'graphql-tag';

export const VIDEO_BLOCK_FRAGMENT = gql`
  fragment VideoBlockFields on VideoBlock {
    sys {
      id
    }
    title
    youtubeLink
    selfHostedVideo {
      title
      dashUrl
      dashCastUrl
      hlsUrl
      duration
    }
  }
`;
