/* eslint-disable unicorn/template-indent */
/* eslint-disable no-secrets/no-secrets */
import gql from 'graphql-tag';

import {
  CAROUSEL_EVENT_FRAGMENT,
  CAROUSEL_TILE_FRAGMENT,
  EVENT_CAROUSEL_FRAGMENT,
  EVENT_CONTENT_BLOCK_FIELDS,
  EVENT_EDITORIAL_CAROUSEL_FRAGMENT,
  EVENT_FILTERED_CAROUSEL_FRAGMENT,
  EVENT_FRAGMENT,
  EVENT_VARIANT_FRAGMENT,
  GALLERY_BLOCK_FRAGMENT,
  GENERIC_CAROUSEL_FRAGMENT,
  GENERIC_HERO_FRAGMENT,
  HERO_FRAGMENT,
  HIGHLIGHTS_BLOCK_FRAGMENT,
  SELLING_POINTS_BLOCK_FRAGMENT,
  SPOTIFY_PLAYLIST_FRAGMENT,
  VIDEO_BLOCK_FRAGMENT,
} from './fragments';
import { ACCORDION_BLOCK_FRAGMENT } from './fragments/accordion-block';
import { TESTIMONIAL_BLOCK_FRAGMENT } from './fragments/testimonial-block';

export const GetEventsByTagQuery = gql`
  ${EVENT_FRAGMENT}
  query GetEventsByTag(
    $tags: [String!]!
    $locale: String!
    $preview: Boolean = false
  ) {
    eventCollection(
      where: { tags_contains_some: $tags }
      locale: $locale
      preview: $preview
    ) {
      items {
        ...EventFields
      }
    }
  }
`;

export const GetPageQuery = gql`
  query GetPageQuery($slug: String!, $locale: String!, $preview: Boolean) {
    pageCollection(
      limit: 1
      where: { slug: $slug }
      locale: $locale
      preview: $preview
    ) {
      items {
        slug
        type
        seo {
          title
          description
          image {
            asset {
              url
            }
          }
        }
      }
    }
  }
`;

export const GetFlexiblePageQuery = gql`
  ${EVENT_CONTENT_BLOCK_FIELDS}
  ${SELLING_POINTS_BLOCK_FRAGMENT}
  ${GALLERY_BLOCK_FRAGMENT}
  ${VIDEO_BLOCK_FRAGMENT}
  ${SPOTIFY_PLAYLIST_FRAGMENT}
  ${HIGHLIGHTS_BLOCK_FRAGMENT}
  ${TESTIMONIAL_BLOCK_FRAGMENT}
  ${GENERIC_HERO_FRAGMENT}
  ${EVENT_FILTERED_CAROUSEL_FRAGMENT}
  ${EVENT_EDITORIAL_CAROUSEL_FRAGMENT}
  ${GENERIC_CAROUSEL_FRAGMENT}
  ${HERO_FRAGMENT}
  ${ACCORDION_BLOCK_FRAGMENT}
  query GetFlexiblePageQuery(
    $slug: String!
    $locale: String!
    $preview: Boolean = false
  ) {
    flexiblePageCollection(
      limit: 1
      where: { slug: $slug }
      locale: $locale
      preview: $preview
    ) {
      items {
        seo {
          title
          description
          image {
            asset {
              url
              width
              height
            }
          }
        }
        slug
        hero {
          ... on Hero {
            ...HeroFields
          }
          ... on GenericHero {
            ...GenericHeroFields
          }
        }
        blocksCollection(preview: $preview) {
          items {
            __typename
            ...ContentBlockFields
            ...SellingPointsSectionFields
            ...GalleryBlockFields
            ...VideoBlockFields
            ...SpotifyPlaylistFields
            ...HighlightsBlockFields
            ...TestimonialBlockFields
            ...EventFilteredCarouselBlockFields
            ...EventEditorialCarouselBlockFields
            ...AccordionBlockFields
            ...GenericCarouselBlockFields
          }
        }
      }
    }
  }
`;

export const GetPressReleasesQuery = gql`
  query GetPressReleasesQuery($preview: Boolean = false) {
    flexiblePageCollection(where: { slug_contains: "pr" }, preview: $preview) {
      items {
        sys {
          publishedAt
        }
        __typename
        slug
        seo {
          image {
            asset {
              url
            }
          }
        }
        blocksCollection(
          where: { title_exists: true }
          limit: 1
          preview: $preview
        ) {
          items {
            ... on ContentBlock {
              title
            }
          }
        }
      }
    }
  }
`;

export const GetPreviewPageByIdQuery = gql`
  ${EVENT_CONTENT_BLOCK_FIELDS}
  query GetPreviewPageByIdQuery($id: String!) {
    previewPageCollection(where: { sys: { id: $id } }, limit: 1) {
      items {
        slug
        accessCode
        seo {
          title
          description
          image {
            asset {
              url
              width
              height
            }
          }
        }
        streamsCollection {
          items {
            title
            hlsUrl
            dashUrl
            dashCastUrl
            duration
          }
        }
        streamSecurity {
          authXml
          certUrl
          poster {
            asset {
              url
              width
              height
            }
          }
          chromecastReceiverAppId
        }
        blocksCollection(where: { title_exists: true }, limit: 1) {
          items {
            __typename
            ...ContentBlockFields
          }
        }
      }
    }
  }
`;

export const GetEventsByIdsQuery = gql`
  ${CAROUSEL_EVENT_FRAGMENT}
  query GetEventsByIds(
    $ids: [String!]!
    $locale: String!
    $limit: Int!
    $skip: Int!
    $preview: Boolean = false
  ) {
    eventCollection(
      limit: $limit
      skip: $skip
      where: { sys: { id_in: $ids } }
      locale: $locale
      preview: $preview
    ) {
      items {
        ...CarouselEventFields
      }
    }
  }
`;

export const GetTilesByIdsQuery = gql`
  ${CAROUSEL_TILE_FRAGMENT}
  query GetTilesByIds(
    $ids: [String!]!
    $locale: String!
    $limit: Int!
    $skip: Int!
    $preview: Boolean = false
  ) {
    tileCollection(
      limit: $limit
      skip: $skip
      where: { sys: { id_in: $ids } }
      locale: $locale
      preview: $preview
    ) {
      items {
        ...CarouselTileFields
      }
    }
  }
`;

export const GetEventSlugByShopifyId = gql`
  query GetEventSlugByShopifyId($shopifyId: String!) {
    simpleEventCollection(where: { shopifyProductId: $shopifyId }) {
      items {
        slug
      }
    }
  }
`;
export const GetMenu = gql`
  query GetMenu($title: String!) {
    menuCollection(where: { title: $title }, limit: 1) {
      items {
        title
        menuGroupsCollection {
          items {
            title
            menuItemsCollection {
              items {
                label
                href
                target
                altText
                ariaLabel
              }
            }
          }
        }
      }
    }
  }
`;

export const GetSeo = gql`
  query GetSlugsAndTypes(
    $preview: Boolean
    $slug: String
    $slugWithSlash: String
  ) {
    simpleEventCollection(
      preview: $preview
      where: { OR: [{ slug: $slug }, { slug: $slugWithSlash }] }
      limit: 1
    ) {
      items {
        seo {
          title
          description
          image {
            asset {
              url
              width
              height
            }
          }
        }
        __typename
        slug
      }
    }
    flexiblePageCollection(
      preview: $preview
      where: { OR: [{ slug: $slug }, { slug: $slugWithSlash }] }
      limit: 1
    ) {
      items {
        seo {
          title
          description
          image {
            asset {
              url
              width
              height
            }
          }
        }
        __typename
        slug
      }
    }
    pageCollection(
      preview: $preview
      where: { OR: [{ slug: $slug }, { slug: $slugWithSlash }] }
      limit: 1
    ) {
      items {
        seo {
          title
          description
          image {
            asset {
              url
              width
              height
            }
          }
        }
        __typename
        slug
        type
      }
    }
    previewPageCollection(
      preview: $preview
      where: { OR: [{ slug: $slug }, { slug: $slugWithSlash }] }
      limit: 1
    ) {
      items {
        seo {
          title
          description
          image {
            asset {
              url
              width
              height
            }
          }
        }
        __typename
        slug
      }
    }
  }
`;

export const GetSlugsQuery = gql`
  query GetSlugsAndTypes($preview: Boolean, $slug: [String]) {
    simpleEventCollection(
      preview: $preview
      where: { slug_in: $slug }
      limit: 1
    ) {
      items {
        sys {
          publishedAt
          id
        }
        __typename
        slug
      }
    }
    venueCollection {
      items {
        sys {
          publishedAt
          id
        }
        title
        displayTitle
      }
    }
    genreCollection {
      items {
        sys {
          publishedAt
          id
        }
        title
        displayLabel
      }
    }
    flexiblePageCollection(
      preview: $preview
      where: { slug_in: $slug }
      limit: 1
    ) {
      items {
        sys {
          publishedAt
          id
        }
        __typename
        slug
      }
    }
    pageCollection(preview: $preview) {
      items {
        sys {
          publishedAt
          id
        }
        __typename
        slug
        type
      }
    }
    previewPageCollection(preview: $preview) {
      items {
        sys {
          publishedAt
          id
        }
        __typename
        slug
      }
    }
  }
`;
export const GetVenueQuery = gql`
  ${EVENT_CONTENT_BLOCK_FIELDS}
  ${SELLING_POINTS_BLOCK_FRAGMENT}
  ${GALLERY_BLOCK_FRAGMENT}
  ${VIDEO_BLOCK_FRAGMENT}
  ${SPOTIFY_PLAYLIST_FRAGMENT}
  ${HIGHLIGHTS_BLOCK_FRAGMENT}
  ${TESTIMONIAL_BLOCK_FRAGMENT}
  ${GENERIC_HERO_FRAGMENT}
  query GetVenueQuery($slug: String!) {
    venueCollection(where: { title: $slug }, limit: 1) {
      items {
        title
        displayTitle
        hero {
          ... on GenericHero {
            ...GenericHeroFields
          }
        }
        blocksCollection {
          items {
            __typename
            ...ContentBlockFields
            ...SellingPointsSectionFields
            ...GalleryBlockFields
            ...VideoBlockFields
            ...SpotifyPlaylistFields
            ...HighlightsBlockFields
            ...TestimonialBlockFields
            ... on EventCarousel {
              sys {
                id
              }
            }
            ... on EditorialCarousel {
              sys {
                id
              }
            }
          }
        }
      }
    }
  }
`;
export const GetGenreQuery = gql`
  ${EVENT_CONTENT_BLOCK_FIELDS}
  ${SELLING_POINTS_BLOCK_FRAGMENT}
  ${GALLERY_BLOCK_FRAGMENT}
  ${VIDEO_BLOCK_FRAGMENT}
  ${SPOTIFY_PLAYLIST_FRAGMENT}
  ${HIGHLIGHTS_BLOCK_FRAGMENT}
  ${TESTIMONIAL_BLOCK_FRAGMENT}
  ${GENERIC_HERO_FRAGMENT}
  query GetGenreQuery($slug: String!) {
    genreCollection(where: { title: $slug }, limit: 1) {
      items {
        title
        displayLabel
        hero {
          ... on GenericHero {
            ...GenericHeroFields
          }
        }
        blocksCollection {
          items {
            __typename
            ...ContentBlockFields
            ...SellingPointsSectionFields
            ...GalleryBlockFields
            ...VideoBlockFields
            ...SpotifyPlaylistFields
            ...HighlightsBlockFields
            ...TestimonialBlockFields
            ... on EventCarousel {
              sys {
                id
              }
            }
            ... on EditorialCarousel {
              sys {
                id
              }
            }
          }
        }
      }
    }
  }
`;

export const contentfulEventsBySlugsQuery = `
query contentfulEventsBySlugs($slugs: [String]!) {
  eventCollection(where:{ slug_in: $slugs}, limit: 50) {
    items {
      sys {
        id
      }
      slug
      title
      shortTitle
      portraitPoster{
        asset {
          __typename
          url
          width
          height
          title
        }
      }
      carouselLogo{
        asset {
          url
          url
          width
          height
          title
        }
      }
      eventInfoLogo{
        asset {
          url
          url
          width
          height
          title
        }
      }
      poster {
        asset {
          url
          url
          width
          height
          title
        }
      }
      portraitPoster {
        asset {
          url
          url
          width
          height
          title
        }
      }
    }
  }
}
`;

export const GetSearchPageQuery = gql`
  ${EVENT_CAROUSEL_FRAGMENT}
  query GetFlexiblePageQuery(
    $slug: String!
    $locale: String!
    $preview: Boolean = false
  ) {
    flexiblePageCollection(
      limit: 1
      where: { slug: $slug }
      locale: $locale
      preview: $preview
    ) {
      items {
        slug
        blocksCollection(limit: 5, preview: $preview) {
          items {
            __typename
            ...EventCarouselBlockFields
          }
        }
      }
    }
  }
`;

export const GetDynamicPageBlocks = (field: 'venue' | 'genres') => gql`
  query GetFlexiblePageQuery(
          $value: String!
      ) {
    simpleEventCollection(where: {${field}: {title_contains: $value}}) {
      items {
        slug
        eventDescription {
          __typename
          title
          image {
            alt
            asset {
              url
            }
          }
          description {
            json
          }
        }
      }
    }
  }
`;
