/* eslint-disable no-secrets/no-secrets */
/* eslint-disable unicorn/template-indent */
import gql from 'graphql-tag';

export const HERO_FRAGMENT = gql`
  fragment HeroFields on Hero {
    __typename
    nickname
    background {
      asset {
        title
        url(transform: { width: 2560, format: JPG, quality: 90 })
        # url
        width
        height
      }
      alt
    }
    eventInfoLogo {
      asset {
        title
        url
        width
        height
      }
      alt
    }
    description {
      json
    }
    theme
  }
`;
