export default {
  newsletter_success: {
    title: 'Successfully signed up',
    body: 'We will send you an overview of upcoming events as well as exclusive behind the scenes of recent events. You will receive one email per 2 weeks, your email address will not be shared with anyone and unsubscribing can be done easily through the emails.',
  },
  newsletter_error: 'An error has occurred, apologies for the inconvenience',
  invalid_email:
    'Please enter your e-mail address in the format yourname@email.com',
  wanna_be_first: 'Want to be the first to hear about our latest shows?',
  sign_up:
    'Sign up for our newsletter and be the first to hear about our latest shows and other fun updates from On Air.',
  subscribe: 'Subscribe',
  policy: {
    by_clicking: 'By clicking sign up you agree with our',
    privacy_policy: 'privacy policy',
    unsubscribe_at_any_time: 'Also know that you can unsubscribe at any time.',
  },
};
