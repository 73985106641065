import { GetMenu } from '@/graphql/queries';
import { normalizeContentfulResponse } from '@/utils/contentful/normalizers';

interface MenuItem {
  label: string;
  href: string;
  altText?: string;
  ariaLabel?: string;
  target?: string;
}

interface MenuGroup {
  title: string;
  menuItems: MenuItem[];
}

interface Menu {
  menuGroups: MenuGroup[];
}

export const fetchMenu = async ({
  title,
  client,
}: {
  title: string;
  client: any;
}) => {
  try {
    const { data } = await client.query({
      query: GetMenu,
      variables: {
        title,
      },
    });
    const normalizedEntries: any = normalizeContentfulResponse(
      data.menuCollection.items,
    );

    if (
      normalizedEntries.length === 0 ||
      !normalizedEntries[0].menuGroupsCollection
    ) {
      throw new Error(`No menu groups found for the ${title} menu.`);
    }

    const menuGroups: Menu = {
      menuGroups: normalizedEntries[0].menuGroupsCollection.items.map(
        (group: any) => ({
          title: group.title,
          menuItems: group.menuItemsCollection.items.map((item: any) => ({
            label: item.label,
            href: item.href,
            altText: item.altText,
            ariaLabel: item.ariaLabel,
            target: item.target || '_self',
          })),
        }),
      ),
    };

    return menuGroups;
  } catch (error: any) {
    console.log('fetchMenu error');
    console.log(error?.networkError?.result?.errors);
  }
};
