/* eslint-disable no-secrets/no-secrets */
/* eslint-disable unicorn/template-indent */
import gql from 'graphql-tag';

export const SPOTIFY_PLAYLIST_FRAGMENT = gql`
  fragment SpotifyPlaylistFields on SpotifyPlaylist {
    sys {
      id
    }
    title
    embeddedPlaylist
    text {
      json
    }
    callToActionButton {
      text
      ariaLabel
      href
    }
  }
`;
