/* eslint-disable no-secrets/no-secrets */
/* eslint-disable unicorn/template-indent */
import gql from 'graphql-tag';

export const EVENT_CONTENT_BLOCK_FIELDS = gql`
  fragment ContentBlockFields on ContentBlock {
    sys {
      id
    }
    title
    subtitle
    type
    description {
      json
    }
    image {
      alt
      asset {
        url
        width
        height
      }
    }
    button {
      text
      type
      ariaLabel
      href
      shouldOpenLinkInNewTab
      isBuyButton
    }
    imagePosition
    shouldShowSocialButtons
    showAppStoreBanners
    showTitle
    socialMediaIconsTitle
    linksCollection {
      items {
        text
        href
        icon {
          type
        }
        shouldFollow
        shouldOpenInNewTab
      }
    }
  }
`;
