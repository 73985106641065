/* eslint-disable no-secrets/no-secrets */
import gql from 'graphql-tag';

export const EVENT_FRAGMENT = gql`
  fragment EventFields on Event {
    sys {
      id
    }
    __typename
    title
    slug
    shortTitle
    tags
    preferredVariant {
      eventStart
    }
    variantsCollection(limit: 2) {
      items {
        doorsOpen
        eventStart
      }
    }
    portraitPoster {
      alt
      asset {
        url(transform: { height: 584, format: JPG, quality: 90 })
        width
        height
        title
      }
    }
    poster {
      alt
      asset {
        url(transform: { height: 584, format: JPG, quality: 90 })
        width
        height
        title
      }
    }
    carouselLogo {
      alt
      asset {
        url(transform: { height: 250, format: PNG })
        width
        height
        title
      }
    }
    eventInfoLogo {
      alt
      asset {
        url(transform: { height: 250, format: PNG })
        width
        height
        title
      }
    }
    genresCollection(limit: 5) {
      items {
        title
        displayLabel
      }
    }
  }
`;
