import { IconLink } from '../components/Socials';
import { ExtendedContentfulLink } from '../types/link';

export const generateShareInSocialsLinks = (): ExtendedContentfulLink[] => {
  let url = '';
  if (typeof window !== 'undefined') {
    url = window.location.href;
  }

  return [
    {
      text: 'Share to Facebook',
      href: `https://www.facebook.com/sharer/sharer.php?u=${url}`,
      icon: {
        type: 'oa-facebook',
      },
    },
    {
      text: 'Share to Twitter',
      href: `http://twitter.com/share?url=${url}`,
      icon: {
        type: 'oa-twitter',
      },
    },
    {
      text: 'Copy link',
      href: '',
      onClick: (e: MouseEvent) => {
        e.preventDefault();
        navigator.clipboard.writeText(url);
      },
      icon: {
        type: 'oa-link',
      },
    },
  ];
};

export const onAirSocialsLinks: IconLink[] = [
  {
    icon: 'oa-facebook',
    href: 'https://www.facebook.com/onairevents/',
  },
  {
    icon: 'oa-instagram1',
    href: 'https://www.instagram.com/onairevents/',
  },
  {
    icon: 'oa-linkedin',
    href: 'https://www.linkedin.com/company/onairbv/mycompany/',
  },
  {
    icon: 'oa-tiktok2',
    href: 'https://www.tiktok.com/@onair.events',
  },
  {
    icon: 'oa-twitter',
    href: 'https://twitter.com/on_air_events',
  },
  {
    icon: 'oa-youtube1',
    href: 'https://www.youtube.com/channel/UCSxg4fRhV3garyt15l4tZ8w',
  },
];
