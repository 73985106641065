'use client';

import React from 'react';
import { ButtonType } from '@/enums/ButtonType';
import ROUTES from '../../constants/routes';
import Button from '../Button';
import Input from '../input';
import LinkInternal from '../LinkInternal';
import messages from './messages';
export interface NewsletterInputProps {
  onChangeInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  subscribe: () => void;
  loading: boolean;
  validationError: boolean;
}
export const NewsletterInput = ({
  onChangeInput,
  subscribe,
  loading,
  validationError
}: NewsletterInputProps) => <div data-sentry-component="NewsletterInput" data-sentry-source-file="NewsletterInput.tsx">
    <h5 className="body-title text-align-center px-5 p-md-0 ">
      {messages.wanna_be_first}
    </h5>
    <p className="body-text mt-1 text-align-center text-center">
      {messages.sign_up}
    </p>
    <div className="row col-12 col-md-12 col-lg-10 col-xl-10 mx-md-auto justify-content-center">
      <div className="d-flex flex-grow-1">
        <div className="flex-grow-1 my-1 mx-1">
          <Input type="email" grow alignError="left" placeholder="yourname@example.com" onChange={onChangeInput} error={validationError ? messages.invalid_email : undefined} data-sentry-element="Input" data-sentry-source-file="NewsletterInput.tsx" />
        </div>
        <div className="col-12 col-sm-4 col-md-4 mt-1 d-none d-md-flex">
          <Button type={ButtonType.SOLID_PRIMARY} onClick={subscribe} className="mt-2 mt-sm-1" grow loading={loading} data-sentry-element="Button" data-sentry-source-file="NewsletterInput.tsx">
            {messages.subscribe}
          </Button>
        </div>
        <div className="d-flex d-md-none mt-2 col-1">
          <Button icon="oa-arrow-right" type={ButtonType.SOLID_PRIMARY} onClick={subscribe} grow className="newsletter-button" loading={loading} data-sentry-element="Button" data-sentry-source-file="NewsletterInput.tsx" />
        </div>
      </div>
    </div>
    <p className="caption-text mt-1">
      {messages.policy.by_clicking}{' '}
      <LinkInternal to={ROUTES.privacyPolicy} className="link__no-style" data-sentry-element="LinkInternal" data-sentry-source-file="NewsletterInput.tsx">
        {messages.policy.privacy_policy}
      </LinkInternal>
      . {messages.policy.unsubscribe_at_any_time}
    </p>
  </div>;