import React, { FC } from 'react';
import { LinkType } from '@/types/Notification';
import ROUTES from '../../constants/routes';
import { AppStoreBanner } from '../app-store-banner';
import LinkInternal from '../LinkInternal';
import DolbyVisionAtmosLogo from '../svgs/DolbyVisionAtmosLogo';
import FourKLogo from '../svgs/FourKLogo';
import WePlantTreesLogo from '../svgs/WePlantTreesLogo';
import messages from './messages';
export interface LogosProps {
  centered?: boolean;
}
export const Logos: FC<LogosProps> = ({
  centered
}) => <div className={`footer__menu-group d-flex flex-column justify-content-center justify-content-md-start col-md-6 col-lg-4 flex-grow-1 ${centered ? 'mx-auto justify-content-between' : ''}`} data-sentry-component="Logos" data-sentry-source-file="Logos.tsx">
    <AppStoreBanner component="footer" data-sentry-element="AppStoreBanner" data-sentry-source-file="Logos.tsx" />
    <div className="footer__logo-row d-flex align-items-center flex-grow-1 my-3 my-md-0">
      <div className="footer__logo-item d-flex flex-row justify-content-center">
        <div className="logo-spacing app-store-banner__icon">
          <FourKLogo data-sentry-element="FourKLogo" data-sentry-source-file="Logos.tsx" />
        </div>
        <LinkInternal to={ROUTES.dolby} className="footer__logo__icon d-flex align-items-center p-0" type={LinkType.LINK_STYLED_IN_COMPONENT} data-sentry-element="LinkInternal" data-sentry-source-file="Logos.tsx">
          <DolbyVisionAtmosLogo data-sentry-element="DolbyVisionAtmosLogo" data-sentry-source-file="Logos.tsx" />
        </LinkInternal>
      </div>
      <div className="footer__logo-item d-flex flex-row align-items-center">
        <LinkInternal to={ROUTES.trees} className="footer__logo__icon d-flex align-items-center p-0" type={LinkType.LINK_STYLED_IN_COMPONENT} data-sentry-element="LinkInternal" data-sentry-source-file="Logos.tsx">
          <div className="footer__trees d-flex flex-row justify-content-center align-items-center p-0">
            <WePlantTreesLogo data-sentry-element="WePlantTreesLogo" data-sentry-source-file="Logos.tsx" />
            <p className="footer-text mx-1 mx-md-0">{messages.trees}</p>
          </div>
        </LinkInternal>
      </div>
    </div>
  </div>;