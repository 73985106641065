/* eslint-disable no-secrets/no-secrets */
import gql from 'graphql-tag';

export const HIGHLIGHTS_BLOCK_FRAGMENT = gql`
  fragment HighlightsBlockFields on HighlightsBlock {
    sys {
      id
    }
    title
    highlightsCollection {
      items {
        title
        text {
          json
        }
        icon {
          type
        }
      }
    }
  }
`;
