'use client';

//TODO: review this
import { useEffect, useState } from 'react';

import { intervalToDuration, isValid } from 'date-fns';

export interface CountDown {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

export function useCountDown(
  endDate?: Date | undefined,
): CountDown | undefined {
  if (!endDate || !isValid(endDate)) return undefined;

  const [currentDate, setCurrentDate] = useState(new Date());
  useEffect(() => {
    const timer = setInterval(() => setCurrentDate(new Date()), 1000);
    return () => clearInterval(timer);
  }, []);

  if (!isValid(endDate)) {
    return undefined;
  }
  if (currentDate >= endDate) return undefined;

  const interval = intervalToDuration({
    start: currentDate,
    end: endDate,
  });
  if (
    interval.days !== undefined &&
    interval.hours !== undefined &&
    interval.minutes !== undefined &&
    interval.seconds !== undefined
  ) {
    interval.months = interval.months ? interval.months * 30 : 0;
    interval.years = interval.years ? interval.years * 365 : 0;
    interval.days = interval.days && interval.days > 0 ? interval.days : 0;

    const duration = {
      days: interval.months + interval.years + interval.days,
      hours: interval.hours,
      minutes: interval.minutes,
      seconds: interval.seconds,
    };
    return duration;
  }
  return undefined;
}
