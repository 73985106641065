export class FetchClient {
  private static instance: FetchClient;

  private cache: Record<string, { data: any; timestamp: number }> = {};
  private CACHE_TTL = 120000;

  constructor() {}

  public static getInstance(): FetchClient {
    if (!FetchClient.instance) {
      FetchClient.instance = new FetchClient();
    }
    return FetchClient.instance;
  }

  // Generate a unique cache key for each query and variables combination
  private generateCacheKey(query: any, variables: any): string {
    return JSON.stringify({ query: query?.loc?.source?.body, variables });
  }

  // Check if the cache is still valid based on TTL
  private isCacheValid(cacheTimestamp: number): boolean {
    const currentTime = Date.now();
    return currentTime - cacheTimestamp < this.CACHE_TTL;
  }

  public async query({ query, variables }: { query?: any; variables?: any }) {
    const uri = `https://graphql.contentful.com/content/v1/spaces/s26j9zm7rn0w/environments/${process.env.CONTENTFUL_ENVIRONMENT}`;

    const cacheKey = this.generateCacheKey(query, variables);

    // Check if response exists in cache and is still valid
    if (
      this.cache[cacheKey] &&
      this.isCacheValid(this.cache[cacheKey].timestamp)
    ) {
      console.log('Returning cached response');
      return this.cache[cacheKey].data; // Return cached data
    }

    // If not in cache or cache has expired, make the API call
    const rawResponse = await fetch(uri, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${
          variables?.preview
            ? process.env.CONTENTFUL_PREVIEW_ACCESS_TOKEN
            : process.env.CONTENTFUL_ACCESS_TOKEN
        }`,
      },
      cache: typeof window === 'undefined' ? 'no-cache' : 'no-store',
      body: JSON.stringify(
        {
          query: query?.loc?.source?.body,
          variables,
        },
        null,
        2,
      )
        .replace(/\n/g, '')
        .replace(/\s\s+/g, ' '),
    });
    const content = await rawResponse.json();

    // Store the response in the cache with a timestamp
    this.cache[cacheKey] = {
      data: content,
      timestamp: Date.now(),
    };

    return content;
  }
}

export const getClient = () => {
  return FetchClient.getInstance();
};
