export const commonTexts = {
  company_name: 'On Air',
  first_name: 'first name',
  last_name: 'last name',
  email_address: 'email address',
  confirm_email_address: 'confirm email address',
  password: 'password',
  current_password: 'current password',
  date: 'date',
  date_of_birth: 'date of birth',
  month: 'month',
  day: 'day',
  year: 'year',
  timezone: 'timezone',
  subscribe_to_news: 'I would like to receive news and updates from On Air',
  accept_terms_and_conditions_1: "I have read and accept On Air's",
  accept_terms_and_conditions_2: ' terms & conditions',
  days: 'days',
  hours_short: 'hrs',
  minutes_short: 'min',
  second_short: 'sec',
  change: 'change',
  remove: 'remove',
  cart_total: 'cart total',
  sub_total: 'subtotal',
  shipping: 'shipping',
  discount: 'discount',
  taxes: 'taxes',
  total: 'total',
  checkout: 'checkout',
  product: 'product',
  related_product: 'related product',
  price: 'price',
  quantity: 'quantity',
  add: 'add',
  subject: 'subject',
  message: 'message',
  category: 'category',
  code: 'code',
  read_more: 'Read more',
  latest_articles: 'Latest articles',
  related_articles: 'Related articles',
  trending: 'Trending',
};
