import React, { FC } from 'react';
import classNames from 'classnames';
import { LinkType } from '@/types/Notification';
import { trackHelpdeskViewed } from '@/utils/analytics';
import ROUTES from '../../constants/routes';
import Icon from '../Icon';
import LinkExternal from '../LinkExternal';
// import { useTranslation } from 'react-i18next';
import LinkInternal from '../LinkInternal';

// import { useTranslation } from "react-i18next";

const NavbarLeft: FC<{
  forceShowsActive: boolean | undefined;
  setDefaultMobileMenuExpanded: (value: boolean) => void;
}> = ({
  forceShowsActive,
  setDefaultMobileMenuExpanded
}) => {
  // const { t } = useTranslation();
  return <>
      <div className="col navbar__link d-none d-md-flex col-auto mr-n1">
        <LinkInternal to={ROUTES.home} className={classNames({
        active: forceShowsActive
      })} ariaLabel="Navigate to Shows"
      // ariaLabel={`${t('global:navigate_to')} ${t(
      //     'homepage:menu.shows',
      // )}`}
      data-sentry-element="LinkInternal" data-sentry-source-file="NavbarLeft.tsx">
          {/* {t('homepage:menu.shows')} */}
          Shows
        </LinkInternal>
      </div>
      <div className="col navbar__link d-none d-md-flex col-auto mr-n1">
        <LinkInternal to={ROUTES.about} type={LinkType.LINK_STYLED_IN_COMPONENT} ariaLabel="Navigate to About"
      // ariaLabel={`${t('global:navigate_to')} ${t(
      //     'homepage:menu.about',
      // )}`}
      data-sentry-element="LinkInternal" data-sentry-source-file="NavbarLeft.tsx">
          {/* {t("homepage:menu.about")} */}
          About
        </LinkInternal>
      </div>
      <div className="col navbar__link d-none d-md-flex col-auto">
        <LinkExternal to={ROUTES.help} type={LinkType.LINK_STYLED_IN_COMPONENT} ariaLabel="Navigate to Help"
      // ariaLabel={`${t('global:navigate_to')} ${t(
      //     'homepage:menu.help',
      // )}`}
      onClick={trackHelpdeskViewed} shouldOpenInNewTab={false} data-sentry-element="LinkExternal" data-sentry-source-file="NavbarLeft.tsx">
          {/* {t('homepage:menu.help')} */}
          Help
        </LinkExternal>
      </div>
      <button type="button" aria-label="toggle main menu" className="col navbar__link navbar__link-hamburger d-flex d-md-none" onClick={() => setDefaultMobileMenuExpanded(true)} data-testid="navbar__link-hamburger">
        <Icon icon="oa-menu" data-sentry-element="Icon" data-sentry-source-file="NavbarLeft.tsx" />
      </button>
      <div className="col d-none d-lg-block" />
    </>;
};
export default NavbarLeft;