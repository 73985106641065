export const segmentScript = () => {
  if (typeof window !== 'undefined') {
    // eslint-disable-next-line no-multi-assign
    const analytics = (window.analytics = window.analytics || []);
    if (
      //@ts-ignore
      !analytics.initialize &&
      //@ts-ignore
      !analytics.invoked &&
      Array.isArray(analytics)
    ) {
      //@ts-ignore
      analytics.invoked = !0;
      //@ts-ignore
      analytics.methods = [
        'trackSubmit',
        'trackClick',
        'trackLink',
        'trackForm',
        'pageview',
        'identify',
        'reset',
        'group',
        'track',
        'ready',
        'alias',
        'debug',
        'page',
        'once',
        'off',
        'on',
        'addSourceMiddleware',
        'addIntegrationMiddleware',
        'setAnonymousId',
        'addDestinationMiddleware',
      ];
      //@ts-ignore
      // eslint-disable-next-line func-names
      analytics.factory = function (e) {
        // eslint-disable-next-line func-names
        return function () {
          const t = Array.prototype.slice.call(
            // eslint-disable-next-line prefer-rest-params
            arguments,
          );
          t.unshift(e);
          //@ts-ignore
          analytics.push(t);
          return analytics;
        };
      };
      for (
        let e = 0;
        //@ts-ignore
        e < analytics.methods.length;
        // eslint-disable-next-line no-plusplus
        e++
      ) {
        //@ts-ignore
        const key = analytics.methods[e];
        //@ts-ignore
        analytics[key] = analytics.factory(key);
      }
      //@ts-ignore
      // eslint-disable-next-line func-names
      analytics.load = function (key, e) {
        const t = document.createElement('script');
        t.type = 'text/javascript';
        t.async = !0;
        t.src =
          // eslint-disable-next-line prefer-template
          'https://cdn.segment.com/analytics.js/v1/' +
          key +
          '/analytics.min.js';
        const n = document.getElementsByTagName('script')[0];
        if (n?.parentNode) {
          n.parentNode.insertBefore(t, n);
        }
        //@ts-ignore
        // eslint-disable-next-line no-underscore-dangle
        analytics._loadOptions = e;
      };
      //@ts-ignore
      // eslint-disable-next-line no-underscore-dangle
      analytics._writeKey = 'ZmxdbpVYyeXyBoleH2qHP80FIjRJ29JI';
      //@ts-ignore
      analytics.SNIPPET_VERSION = '4.15.3';
    }
  }
  return null;
};
