/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { useEffect, useState } from 'react';

export const useIsPreviewMode = () => {
  // TODO: The previewAccessToken has to be passed in as a prop dynamically with the noStore()
  // Doesn't work at the moment.
  const [isPreviewCookieExist, setIsPreviewCookieExist] = useState(false);

  useEffect(() => {
    if (typeof document === 'undefined') return setIsPreviewCookieExist(false);

    const cookies = Object.fromEntries(
      document.cookie
        .split(';')
        .map((cv) => [cv.split('=')[0].trim(), cv.split('=')[1]]),
    ) as unknown as never;

    if (!cookies?.['preview_mode']) return setIsPreviewCookieExist(false);

    const previewCookieAccepted = cookies?.['preview_mode'] === 'true';
    setIsPreviewCookieExist(previewCookieAccepted);
  }, []);
  return isPreviewCookieExist;
};
