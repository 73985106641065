/* eslint-disable no-secrets/no-secrets */
/* eslint-disable unicorn/template-indent */
import gql from 'graphql-tag';

export const GENERIC_HERO_FRAGMENT = gql`
  fragment GenericHeroFields on GenericHero {
    __typename
    title
    subtitle
    sublineText
    background {
      asset {
        title
        url(transform: { width: 2560, format: JPG, quality: 90 })
        width
        height
      }
      alt
    }
    button {
      text
      type
      ariaLabel
      href
      shouldOpenLinkInNewTab
      isBuyButton
    }
    video {
      __typename
      title
      hlsUrl
      duration
      thumbnailImage {
        asset {
          title
          url(transform: { width: 2560, format: PNG, quality: 90 })
          width
          height
        }
        alt
      }
    }
    logo {
      title
      asset {
        url(transform: { width: 2560, format: PNG, quality: 90 })
        width
        height
      }
    }
    layout
  }
`;
