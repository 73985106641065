'use client';

import { useEffect, useState } from 'react';

import { Platform } from '@/enums/Platforms';

export const usePlatform = () => {
  const [platform, setPlatform] = useState<Platform>('web');

  useEffect(() => {
    if (window) {
      if (/iPhone|iPad|iPod/i.test(window.navigator.userAgent)) {
        setPlatform('ios');
      } else if (/android/i.test(window.navigator.userAgent)) {
        setPlatform('android');
      }
    }
  }, []);

  return { platform };
};
